import React from "react"

import PageHeader from "../../components/layout/PageHeader"
import SupportPage from "../../components/layout/SupportPage"
import GoogleImage from "../../components/GoogleImage"
import PDFDownloadBox from "../../components/PDFDownloadBox"

const InstallationDmPage = () => {
	return (
		<SupportPage page="installation" title="Stud Mount Installation Instructions">
			<PageHeader title="Stud Mount Installation Instructions" />

            <PDFDownloadBox
				title="Download Installation Instructions"
                pdfUrl="documents/SignMonkey-Installation-Stud-Mount.pdf"
				imageUrl="SignTypes/Install-Guide-thumb.jpg"
            />

			<h3>Step 1 <small>Level & Center Pattern</small></h3>
            <p>Unroll the pattern and tape to the wall surface where you will be installing your sign.</p>
            <p>Center the pattern, then level. Do not level from the top or bottom of the paper, only level from the horizontal line printed on the front.</p>
			<div>
				<GoogleImage src="Documents/installation-stud-mount-image1.png" required lazy alt="Level & Center Pattern" className="full" />
			</div>

			<h3>Step 2 <small>Drill Holes</small></h3>
            <p>Drill the mountng holes. The template will show you where to drill each hole for each letter.</p>
            <p>Remove the pattern from the wall. If necessary, blow the holes out with a straw to remove dust.</p>
			<div>
				<GoogleImage src="Documents/installation-stud-mount-image2.png" required lazy alt="Drill Holes" className="full" />
			</div>

			<h3>Step 3 <small>Attach Studs</small></h3>
            <p>Attach the threaded studs (provided with your sign) to the back of the letters.</p>
			<div>
				<GoogleImage src="Documents/installation-stud-mount-image3.png" required lazy alt="Attach Studs" className="full" />
			</div>

			<h3>Step 4 <small>Apply Silicone</small></h3>
            <p>Apply Silicone to the threaded studs on the back of the letter.</p>
			<div>
				<GoogleImage src="Documents/installation-stud-mount-image4.png" required lazy alt="Apply Silicone" className="full" />
			</div>

			<h3>Step 5 <small>Apply Letters</small></h3>
            <p>Attach the letters to the wall by insertng the threaded studs into the pre-drilled holes in the wall.</p>
            <p>Tape any loose letters to the wall and allow 3-4 hours to set before removing tape.</p>
			<div>
				<GoogleImage src="Documents/installation-stud-mount-image5.png" required lazy alt="Apply Letters" className="full" />
			</div>

			<div className="warning glasses">
				<GoogleImage src="Documents/safteyglasses.png" required lazy alt="Safety Glasses" />
				Wear Safety Glasses when drilling holes
			</div>
		</SupportPage>
	)
}

export default InstallationDmPage